export const ProductItems = [
    {
        name: 'Floor Cleaner',
        price: ' ',
        img: require('../../../assets/floor-cleaner.jpg').default,
        msds: require('../../../assets/msds/floor-cleaner.pdf').default
    },
    {
        name: 'Glass Cleaner',
        price: ' ',
        img: require('../../../assets/glass-cleaner.jpg').default,
        msds: require('../../../assets/msds/glass-cleaner.pdf').default
    },
    {
        name: 'Fantastic Cleaner',
        price: ' ',
        img: require('../../../assets/kitchen-all-purpose.jpg').default,
        msds: require('../../../assets/msds/kitchen.pdf').default
    },
    {
        name: 'Oven Cleaner',
        price: ' ',
        img: require('../../../assets/oven-cleaner.jpg').default,
        msds: require('../../../assets/msds/oven-cleaner.pdf').default
    },
    {
        name: 'Pink Hand Soap',
        price: ' ',
        img: require('../../../assets/pink-lotion-soap.jpg').default,
        msds: require('../../../assets/msds/pink-lotion.pdf').default
    },
    {
        name: 'Pots and Pans',
        price: ' ',
        img: require('../../../assets/pots-pans.jpg').default,
        msds: '../../../assets/msds/'
    },
    {
        name: 'Pink Soap Powder',
        price: ' ',
        img: require('../../../assets/pink-soap-powder.jpg').default,
        msds: require('../../../assets/msds/pink-soap-powder.pdf').default
    }
]