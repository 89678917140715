import React from 'react'; 
import { MenuItems } from './MenuItems';
import { Link }from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/strong-power-logo.png';

export class Navbar extends React.Component {
  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  }

  render() {
    return(
      <nav className="NavbarItems">
        <Link className="navbar-logo" to='/'>
          <img src={logo} alt="strong power logo"/>
          <h1> Strong Power</h1>
        </Link>
        <div className="menu-icon" onClick={this.handleClick}>
          <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' :  'nav-menu'}>
          {MenuItems.map((item, index) => {
            return(
              <li key={index}>
                <Link onClick={this.handleClick} className={item.cName} to={item.url}>{item.title}</Link>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }
}