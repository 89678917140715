import { Navbar } from './components/Navbar/Navbar';
import { 
  BrowserRouter as Router,
  Switch,
  Route } from 'react-router-dom';
import './App.css';
import Products from './components/pages/Products/Products';
import Home from './components/pages/Home';

const routes = [
  {
    path:'/',
    component: Home
  },
  {
    path:'/about',
    component: About
  },
  {
    path:'/contact',
    component: Contact
  },
  {
    path:'/products',
    component: Products
  }
]

function App() {
  return (
    <Router>
    <Navbar />
    <div className="App">
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            children={<route.component />}
          />
        ))}
        <Route component={NotFound} />
      </Switch>
    </div>
      </Router>
  );
}

export default App;

function About() {
  return (
    <div>
      <h1>About</h1>
      <p>
        U.S. Strong Power brings you industry-proven and effective cleaning products that have helped our customers for years.
      </p>
    </div>
  )
}

function Contact() {
  return (
    <div>
      <h1>Contact</h1>
      <p>
        Jack Tran, Manager <br/>
        usstrongpower@gmail.com <br/>
        Office: +1 (713) 781-4815 <br/>
      </p>
    </div>
  )
}

function NotFound() {
  return (
    <div>
      <h1>Page Not Found!</h1>
      <p>The page you're looking for isn't here!</p>
    </div>
  )
}
