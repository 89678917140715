import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
	Grid
} from '@mui/material';
import './Products.css';
import { ProductItems } from '../Products/ProductsList';

export default function Products() {

	return (
		<>
		<Typography pt={'10px'}>Please click or tap on a product to see its MSDS</Typography>
		<div className="container">
			<Grid container spacing={2}>
				{ProductItems.map((item, index) => {
					return(
					<Grid key={index} item xs>
						<CardActionArea 
							className='action-area'>
							<a className="card-text" href={item.msds} without rel="noopener noreferrer" target="_blank">
							<Card className='card'>
								<CardMedia className='media' image={item.img} />
								<CardContent>
									<Typography>
										{item.name}
									</Typography>
								</CardContent>
							</Card>
							</a>
						</CardActionArea>
					</Grid>
					)
				})}
			</Grid>
		</div>
		</>
	)
}