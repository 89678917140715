export const MenuItems = [
  {
    title: 'Home',
    url:'/',
    cName: 'nav-links'
  },
  {
    title: 'About',
    url:'/about',
    cName: 'nav-links'
  },
  {
    title: 'Contact',
    url:'/contact',
    cName: 'nav-links'
  },
  {
    title: 'Products',
    url:'/products',
    cName: 'nav-links'
  },
  // {
  //   title: 'Order',
  //   url:'/',
  //   cName: 'nav-links-mobile'
  // },
]