import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import './Home.css';
import { Link } from 'react-router-dom';
import { 
  Grid, 
  Typography, 
  Card, 
  CardMedia, 
  CardContent,
  Button } from '@mui/material';

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const images =[
  {
    name: 'Products',
    alt: 'chemical products',
    src: require('../../assets/products.jpg').default
  },
  {
    name: 'Hero',
    alt: 'person in chemical gear',
    src: require('../../assets/hero.jpg').default
  },
  {
    name: 'Tank Regulation',
    alt: 'workers checking tanks',
    src: require('../../assets/tank-regulation.jpg').default
  }
]

const middleCards = [
  {
    icon: require('../../assets/icons/laboratory.png').default,
    description: 'Best in class chemical formulations'
  },
  {
    icon: require('../../assets/icons/gas-stove.png').default,
    description: 'Industry-grade cleaning and restaurant-safe'
  },
  {
    icon: require('../../assets/icons/wipe.png').default,
    description: 'For all industrial and commercial cleaning needs'
  },
]

export default function Home() {

  return(
    <>
    <div>
      <h1>U.S. Strong Power Inc.</h1>
      <p>Your place for USA-made cleaning products</p>
    </div>

    <Swiper
      className="swiper-container"
      loop={true}
      navigation={true}
      autoplay={{delay: 3500, disableOnInteraction: true}}>
      { images.map((image, index) => {
        return(
          <SwiperSlide key={index}>
            <img src={image.src} alt={image.alt}/>
          </SwiperSlide>
        )
      })}
    </Swiper>
    <div className="productsButton">
      <Button component={Link} to="/products"variant="contained">Products</Button>
    </div>
    <Grid 
      sx={{
        padding: '2em',
        justifyContent: 'space-evenly'
      }}
      spacing={{xs:2}}
      container >
      { middleCards.map((card, index) => {
        return(
          <Grid 
            key={index} 
            item>
            <Card
              sx={{
                height: 250,
                width: 250,
                display:'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                borderRadius: '12px',
                background: 'lightgrey'
              }}>
              <CardMedia 
                component="img"
                sx={{
                  height: 100,
                  width:100
                }} 
                src={card.icon}/>
              <CardContent>
                <Typography variant='body2'>
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </Grid>
    
    <div>
      <h3>Contact</h3>
      <p>
        Jack Tran, Manager <br/>
        usstrongpower@gmail.com <br/>
        Office: +1 (713) 781-4815 <br/>
      </p>
    </div>
    </>
  )
}